import classNames from 'classnames';
import { HTMLAttributeAnchorTarget, MouseEventHandler, PropsWithChildren, type JSX } from 'react';

import Link from '@lichtblick/link';

import styles from './box-cta.module.scss';
import { texts } from './box-cta.texts';

type BoxCTABaseBaseProps = {
  className?: string;
  'data-dd-action-name'?: string;
  'data-testid'?: string;
  size?: 'large' | 'medium' | 'small';
  tabIndex?: number;
  theme?: 'light' | 'dark';
  wide?: boolean;
};

type BoxCTAVariantProps = BoxCTABaseBaseProps & {
  variant?: 'primary' | 'destructive';
};

type BoxCTAVariantNeutralProps = BoxCTABaseBaseProps & {
  fill?: 'filled' | 'tonal' | 'outline';
  variant: 'neutral';
};

type BoxCTAVariantsProps = BoxCTAVariantProps | BoxCTAVariantNeutralProps;

type BoxCTAWithChildrenProps = BoxCTAVariantsProps & PropsWithChildren & { ariaLabel?: string; leftSlot?: JSX.Element };
type BoxCTAWithoutChildrenProps = BoxCTAVariantsProps & { ariaLabel: string; leftSlot: JSX.Element };
type BoxCTABaseProps = BoxCTAWithChildrenProps | BoxCTAWithoutChildrenProps;

export type BoxCTAButtonProps = BoxCTABaseProps & {
  as: 'button';
  disabled?: boolean;
  formId?: string;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: React.ButtonHTMLAttributes<undefined>['type'];
};

export type BoxCTAAnchorProps = BoxCTABaseProps & {
  as: 'a';
  className?: string;
  download?: boolean;
  href: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: HTMLAttributeAnchorTarget;
};

export type BoxCTAProps = BoxCTAButtonProps | BoxCTAAnchorProps;

export const BoxCTA: React.FC<BoxCTAProps> = (props) => {
  const {
    ariaLabel,
    as,
    className,
    'data-dd-action-name': ddAction,
    'data-testid': testId,
    leftSlot,
    size = 'large',
    tabIndex,
    theme = 'light',
    variant = 'primary',
    wide,
  } = props;
  const children = 'children' in props ? props.children : undefined;
  const stylesFill = props.variant === 'neutral' ? styles[props.fill ?? 'outline'] : undefined;

  const ctaClasses = classNames(
    'box-cta',
    styles.cta,
    styles[theme],
    styles[variant],
    stylesFill,
    styles[size],
    wide && styles.wide,
    className,
  );

  if (as === 'a') {
    const { download = false, href, onClick, target } = props;

    return (
      <Link
        aria-label={ariaLabel}
        className={ctaClasses}
        data-dd-action-name={ddAction}
        data-testid={testId}
        download={download}
        href={href}
        onClick={onClick}
        tabIndex={tabIndex}
        target={target}
      >
        {leftSlot && <span className={styles.slot}>{leftSlot}</span>}
        {children && <span className={styles.text}>{children}</span>}
      </Link>
    );
  }

  const { disabled, formId, loading, onClick, type } = props;

  return (
    <button
      aria-label={ariaLabel}
      className={classNames(ctaClasses, loading && styles.loading)}
      data-dd-action-name={ddAction}
      data-testid={testId}
      disabled={disabled || loading}
      form={formId}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
    >
      {leftSlot && <span className={styles.slot}>{leftSlot}</span>}
      {children && <span className={styles.text}>{loading ? texts.label.loading : children}</span>}
    </button>
  );
};

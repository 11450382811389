import classNames from 'classnames';
import { ElementType, FC } from 'react';

import styles from './field-footer.module.scss';

import { Text } from '../../atoms/text/text';

export type FieldFooterState = 'default' | 'error' | 'success';

export type FieldFooterProps = {
  className?: string;
  icon?: ElementType;
  id?: string;
  label: string | undefined;
  state?: FieldFooterState;
};

export const FieldFooter: FC<FieldFooterProps> = ({
  className,
  icon: Icon,
  id,
  label: fieldFooterLabel,
  state = 'default',
}) => (
  <Text
    className={classNames('field-footer', styles['field-footer'], styles[`state-${state}`], className)}
    data-testid="field-footer"
    id={id}
    size="xs"
  >
    {Icon && (
      <span className={classNames(styles.icon)}>
        <Icon />
      </span>
    )}
    <span>{fieldFooterLabel}</span>
  </Text>
);
